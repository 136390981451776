import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import { SLACK_WEBHOOK_URL } from '../../../const';
import useUserLocale, { openChatBot } from '../../../function';
import { useZogrosContext } from '../../../providers/ZogrosProvider';
import AnimatedCard from './AnimatedCard';

const StepTitle = ({ color, title, icon }) => {
    return (
        <div className='d-flex align-items-center'>
            <div
                className={` bg-${color}-soft icon-box d-inline-block rounded-circle my-4`}
                style={{ borderRadius: '200px' }}
            >
                <i className={`fas ${icon} text-${color}`}></i>

            </div><p className={`m-2 text-${color}`} style={{ fontSize: '16px', fontWeight: 'bolder' }}>
                {title}
            </p>
        </div >
    );
};

const ControlButtons = () => {

    const { nextStep, previousStep, isFirstStep, isLastStep, goToStep, activeStep } = useWizard();
    const { data, setData } = useZogrosContext();

    const clearData = () => {
        setData({});
    };

    // Check if the form is complete
    const isFormCompleteStep1 = data?.name && data?.email && data?.company && data?.number;
    const isFormCompleteStep2 = data?.budget && data?.services;
    const isFormCompleteStep3 = data?.deadline && data?.project;

    return (
        <>
            <div className={`col-12 d-flex  ${isFirstStep ? "justify-content-end" : "justify-content-between"} mt-4`}>
                {!isFirstStep && !isLastStep && <button className="btn btn-outline-secondary btn-sm" onClick={() => previousStep()}>Back</button>}
                {activeStep === 0 &&
                    <button className="btn btn-primary btn-sm" onClick={() => nextStep()} disabled={!isFormCompleteStep1}>
                        Next
                    </button>
                }
                {activeStep === 1 &&
                    <button className="btn btn-primary btn-sm" onClick={() => nextStep()} disabled={!isFormCompleteStep2}>
                        Next
                    </button>
                }
                {activeStep === 2 &&
                    <button className="btn btn-primary btn-sm" onClick={() => nextStep()} disabled={!isFormCompleteStep3}>
                        Next
                    </button>
                }
                {isLastStep && <button className="btn  btn-outline-secondary btn-sm" onClick={() => { goToStep(0); clearData(); }}>Start Over</button>}
                {isLastStep && <button className="btn btn-primary btn-sm" onClick={() => openChatBot()}>Start Chat</button>}
            </div>
        </>
    );
}

const WizardCard = () => {
    const previousStep = React.useRef(0);
    const steps = [<Step1 />, <Step2 />, <Step3 />, <Step4 />];
    return (
        <>
            <div className="col-12 col-lg-11 ">
                <div className="register-wrap px-4 pb-4 pt-2 border bg-white rounded-custom">
                    <Wizard
                        startIndex={0}
                        wrapper={<AnimatePresence wait />}
                    >
                        {Array(4)
                            .fill(null)
                            .map((_, index) => {
                                return (
                                    <AnimatedCard key={index} previousStep={previousStep}>
                                        {steps[index]}
                                    </AnimatedCard>
                                );
                            })}
                    </Wizard>
                </div>
            </div>
        </>
    );
};

const Step1 = () => {

    const { data, setData } = useZogrosContext();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <>
            <StepTitle color="success" title="Personal Details" icon="fa-phone" />
            <div className='row'>
                <div className='col-6 '>
                    <label htmlFor='name' className='mb-1'>
                        Name <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <input
                            id='name'
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Joe"
                            defaultValue={data?.name}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-6 '>
                    <label htmlFor='email' className='mb-1'>
                        Email <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <input
                            type="text"
                            name="email"
                            id='email'
                            defaultValue={data?.email}
                            className="form-control"
                            placeholder="joe@joeland.com"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className='col-6 '>
                    <label htmlFor='company' className='mb-1'>
                        Company <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <input
                            type="text"
                            name="company"
                            id='company'
                            defaultValue={data?.company}
                            className="form-control"
                            placeholder="Joe Co"
                            onChange={handleChange}
                        />
                    </div>
                </div>

                <div className='col-6 '>
                    <label htmlFor='number' className='mb-1'>
                        Phone Number <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <input
                            type="text"
                            name="number"
                            id='number'
                            defaultValue={data?.number}
                            className="form-control"
                            placeholder="+441234567890"
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
            {/* <button className="btn btn-primary mt-4" onClick={() => nextStep()}>Next</button> */}
            <ControlButtons />
        </>
    );
};

const Step2 = () => {

    const { data, setData } = useZogrosContext();
    const userLocale = useUserLocale();


    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };
    return (
        <>

            <StepTitle color="info" title="Budget & Services" icon="fa-cog" />
            <div className='row'>
                <div className='col-sm-12 '>
                    <label htmlFor='budget' className='mb-1'>
                        What is Your Budgest <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <select
                            className='form-control form-select'
                            name='budget'
                            id='budget'
                            required=''
                            defaultValue={data?.budget}
                            data-msg='Please select your budget.'
                            onChange={handleChange}
                        >
                            {userLocale === "en-GB" ?
                                <>
                                    <option value=''>Select one of the options</option>
                                    <option value='£1K-£5K'>£1,000 to £5,000</option>
                                    <option value='£5K-£15K'>£5,000 to £15,000</option>
                                    <option value='£15K-£50K'>£15,000 to £50,000</option>
                                    <option value='M£50K'>More than £50,000</option>
                                </>
                                :
                                <>
                                    <option value=''>Select one of the options</option>
                                    <option value='$1K-$5K'>$1,000 to $5,000</option>
                                    <option value='$5K-$15K'>$5,000 to $15,000</option>
                                    <option value='$15K-$50K'>$15,000 to $50,000</option>
                                    <option value='M$50K'>More than $50,000</option>
                                </>
                            }

                        </select>
                    </div>
                </div>
                <div className='col-sm-12 '>
                    <label htmlFor='services' className='mb-1'>
                        What is Services are You Intrested <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <select
                            className='form-control form-select'
                            name='services'
                            id='services'
                            defaultValue={data?.services}
                            required=''
                            data-msg='Please select your budget.'
                            onChange={handleChange}
                        >
                            <option value=''>Select one of the options</option>
                            <option value='AI'>AI</option>
                            <option value='SEO'>SEO</option>
                            <option value='Consultancy'>Consultancy</option>
                            <option value='Analytics'>Analytics</option>
                            <option value='URL'>URL Management</option>
                            <option value='Creative'>Creative Work</option>
                            <option value='more'>More than one service</option>
                        </select>

                    </div>
                </div>
                {data?.services === 'more' ?
                    <div className='col-12 '>
                        <label htmlFor='more' className='mb-1'>
                            Tell us more <span className='text-danger'>*</span>
                        </label>
                        <div className='input-group mb-3'>
                            <textarea
                                name="more"
                                defaultValue={data?.more}
                                id='more'
                                className="form-control"
                                placeholder="Tell us which services or apps you would require"
                                onChange={handleChange}
                            />
                        </div>
                    </div> : null
                }
            </div>
            <ControlButtons />
        </>
    );
};

// Implement Step3, Step4, and Step5 similarly for your remaining questions

const Step3 = () => {
    const { handleStep } = useWizard();

    const { data, setData } = useZogrosContext();
    const userLocale = useUserLocale();

    // This handler is optional
    handleStep(() => {
        // const { name, email, /* ... other fields */ } = formData;
        // const userAnswers = `Name: ${name}\nEmail: ${email}\n/* ... other fields */`;

        const payload = {
            channel: '#new-website',
            username: 'Zog Bot',
            text: `Name: ${data?.name},\nPhone: ${data?.number},\nEmail: ${data?.email},\nCompany: ${data?.company},\nServices: ${data?.services},\nMore: ${data?.more}, \nBudget: ${data?.budget},\nDeadline: ${data?.deadline},\nProject: ${data?.project}`,


            attachments: [
                {
                    fallback: `New Website Submission ${userLocale === "en-GB" ? 'GB' : 'Rest of the World'}`,
                    pretext: `New Website Submission ${userLocale === "en-GB" ? 'GB' : 'Rest of the World'}`,
                    color: userLocale === "en-GB" ? '#50C878' : '#FF5733',
                    fields: [
                        {
                            "title": data?.name,
                            "value": `Name: ${data?.email}, Phone: ${data?.number}`,
                            "short": false
                        }
                    ]
                }
            ]
        };

        fetch(SLACK_WEBHOOK_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `payload=${JSON.stringify(payload)}`,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.text();
            })
            .then((data) => {
                console.log('Message sent to Slack:', data);
            })
            .catch((error) => {
                console.error('Error sending message to Slack:', error);
            });


    });

    const handleChange = (e) => {
        const { name, value } = e.target;

        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

    };
    return (
        <>

            <StepTitle color="warning" title="Projects & Deadlines" icon="fa-flag-checkered" />
            <div className='row'>
                <div className='col-sm-12 '>
                    <label htmlFor='deadline' className='mb-1'>
                        What is Your Deadline <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <select
                            className='form-control form-select'
                            name='deadline'
                            id='deadline'
                            defaultValue={data?.deadline}
                            required
                            onChange={handleChange}
                        >

                            <option value=''>Select one of the options</option>
                            <option value='L1M'>Less than a month</option>
                            <option value='M1M'>More than a month</option>
                            <option value='ND'>No deadline</option>
                        </select>
                    </div>
                </div>

                <div className='col-12 '>
                    <label htmlFor='project' className='mb-1'>
                        Tell us about your project <span className='text-danger'>*</span>
                    </label>
                    <div className='input-group mb-3'>
                        <textarea
                            name="project"
                            id='project'
                            defaultValue={data?.project}
                            rows="5"
                            className="form-control"
                            placeholder="Tell us about your projects "
                            onChange={handleChange}
                        />
                    </div>
                </div>

            </div>
            <ControlButtons />
        </>
    );
}


const Step4 = () => {

    return (
        <>
            <StepTitle color="success" title="Submission Successful" icon="fa-check" />
            <p>Thank you for submitting your message. We have received it and will respond to you promptly via the email address you provided. </p><p>
                In the meantime, please don't hesitate to use our chat feature if you have any additional information to share or any questions you'd like to ask. Your satisfaction is our priority, and we're here to assist you further.
            </p>

            <ControlButtons />
        </>
    );
};

export default WizardCard;