import React from 'react';
import SectionTitle from '../../components/common/SectionTitle';

const ZogrosFeatureFour = () => {
  return (
    <>
      <section className='feature-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle=''
                title='Empowering Digital Evolution'
                description='Comprehensive solutions tailored to navigate your business through the digital frontier.'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-magnifying-glass text-success fa-2x'></i>
                  </div>
                  <h3 className='h5'>Technical SEO & Performance</h3>
                  <p className='mb-0'>
                    We diagnose and resolve critical SEO challenges, ensuring your website operates at peak performance, drawing in the right traffic.
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0'>
                  <div className='feature-icon d-inline-block bg-danger-soft rounded-circle mb-32'>
                    <i className='fad fa-brain text-danger fa-2x'></i>
                  </div>
                  <h3 className='h5'>AI-Powered Content</h3>
                  <p className='mb-0'>
                    Leveraging advanced AI and machine learning, we craft unique and engaging content that resonates with your target audience, across various industries.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-dark-soft rounded-circle mb-32'>
                    <i className='fad fa-handshake text-dark fa-2x'></i>
                  </div>
                  <h3 className='h5'>Expert Consultancy
                  </h3>
                  <p className='mb-0'>
                    Our seasoned experts offer personalized guidance and training, enabling businesses to stay a step ahead in the evolving digital landscape.
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZogrosFeatureFour;
