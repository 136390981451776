import React from 'react';
import { Link } from 'react-router-dom';
import { useHeight } from "../../function";
import GradientPanel from './GradientPanel';


const ZogrosNotFound = () => {
  const windowHeight = useHeight(); // Use the useHeight hook to get the window height
  return (
    <>
      <GradientPanel />
      <div className='d-flex position-relative flex-column justify-content-center  align-items-center' style={{ zIndex: 99999, height: `${windowHeight}px`, width: "100vw" }}>
        <Link to='/' className='mb-5 d-block'>
          <img
            src='assets/img/brand-logo/light-text.svg'
            alt='logo'
            className='img-fluid'
            width="150"
          />
        </Link>

        <h1 className='text-white display-1 fw-bold'>404</h1>
        <p className=' fs-5 fw-light text-light mb-5'>Page not found</p>
        <div className='action-btns'>
          <Link
            to='/'
            className='btn  btn-light  mt-4'
          >
            Back to home page
          </Link>
        </div>
      </div>

    </>
  );
};

export default ZogrosNotFound;
