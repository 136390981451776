import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import Layout from '../../layout/Layout';
import ZogrosNotFound from './ZogrosNotFound';


const ZogrosNotFoundPage = () => {
  return (
    <Layout>
      <PageMeta title='404' />
      <ZogrosNotFound />
    </Layout>
  );
};

export default ZogrosNotFoundPage;
