import { useEffect, useState } from 'react';

export const useHeight = () => {
    const [height, setHeight] = useState(window.innerHeight);

    const updateHeight = () => {
        setHeight(window.innerHeight);
    };

    useEffect(() => {
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return height;
};

export const handleClickScroll = (componentName) => {
    const element = document.getElementById(componentName);
    if (element) {
        // Calculate the offset including padding (50px)
        const offset = element.offsetTop - 70;
        // Scroll smoothly to the adjusted offset
        window.scrollTo({
            top: offset,
            behavior: 'smooth',
        });
    }
};

export function useActualScreenSize() {
    const [screenSize] = useState({
        width: window.screen.width,
        height: window.screen.height,
    });

    // No need for an event listener here since the actual screen size doesn't change when the window resizes.

    return screenSize;
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent =
            typeof window.navigator === 'undefined' ? '' : navigator.userAgent;

        // This is a simple regex for detecting most mobile devices. You can expand this for more accuracy.
        const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

        setIsMobile(mobileRegex.test(userAgent));
    }, []);

    return isMobile;
}

function useUserLocale() {
    const [userLocale, setUserLocale] = useState(navigator.language);

    useEffect(() => {
        // Function to update userLocale when language changes
        const updateLocale = () => {
            setUserLocale(navigator.language);
        };

        // Listen for the 'languagechange' event to detect changes in language/locale
        window.addEventListener('languagechange', updateLocale);

        return () => {
            // Remove the event listener when the component unmounts
            window.removeEventListener('languagechange', updateLocale);
        };
    }, []);

    return userLocale;
}

export default useUserLocale;

export function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
}

export function openChatBot() {
    const buttonStyle = 'background: linear-gradient(95deg, rgb(56, 61, 69) 20%, rgb(56, 61, 69) 80%);';
    const buttonByStyle = document.querySelector(`[style="${buttonStyle}"]`);

    if (buttonByStyle) {
        buttonByStyle.click();
    } else {
        console.error('Webchat button not found.');
    }
}