import React, { Component } from 'react';
import PageMeta from '../../components/common/PageMeta';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import { ZogrosProvider } from '../../providers/ZogrosProvider';
import ZogrosFeatureOne from './ZogrosFeatureOne';
import ZogrosFooter from './ZogrosFooter';
import ZogrosHero from './ZogrosHero';
import ZogrosIntegrationList from './ZogrosIntegrationList';
import ZogrosProcessTab from './ZogrosProcessTab';
import ZogrosSmartWizard from './ZogrosSmartWizard';
import ZogrosSoftwareList from './ZogrosSoftwareList';


class ZogrosMain extends Component {

  render() {
    return (
      <Layout>
        <ZogrosProvider>
          <PageMeta title='Zogros' />
          <Navbar navDark />
          <ZogrosHero />
          <ZogrosFeatureOne />
          <ZogrosProcessTab />
          {/* <FeatureImg /> */}
          {/* <TestimonialOne darkBg /> */}
          {/* <PriceOne paddingTop='pt-60 pb-120' haspriceTitleWithHeader /> */}
          <ZogrosIntegrationList />
          <ZogrosSoftwareList />
          {/* <FaqTwo /> */}
          <ZogrosSmartWizard />
          {/* <CtaOne /> */}
          <ZogrosFooter footerGradient />
        </ZogrosProvider>
      </Layout>
    );
  }
}

export default ZogrosMain;
