import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/common/SectionTitle';
import ProcessFlow from './ZogrosReusable/ProcessFlow';

const ZogrosProcessTab = () => {
  return (
    <>
      <section className='feature-tab-section ptb-120 bg-white'>
        <div className='container'>
          <div className='row justify-content-center align-content-center'>
            <div className='col-md-10 col-lg-6'>
              <SectionTitle
                subtitle='Work Process'
                title='Our Comprehensive Processes'
                description='Explore the array of services we offer, each meticulously crafted to elevate your online presence and business success. Discover how we seamlessly integrate expertise and innovation into every step of your journey.'
                centerAlign
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <ul
                className='nav justify-content-center feature-tab-list-2 gap-3 '
                id='nav-tab'
                role='tablist'
              >
                <li className='nav-item '>
                  <Link
                    className='nav-link active'
                    to='#tab-1'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-1'
                    role='tab'
                    aria-selected='false'
                  >
                    SEO
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-2'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-2'
                    role='tab'
                    aria-selected='false'
                  >
                    AI
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-3'
                    role='tab'
                    aria-selected='false'
                  >
                    Analytics
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-4'
                    role='tab'
                    aria-selected='false'
                  >
                    Creative
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-5'
                    role='tab'
                    aria-selected='false'
                  >
                    URL
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    className='nav-link'
                    to='#tab-3'
                    data-bs-toggle='tab'
                    data-bs-target='#tab-6'
                    role='tab'
                    aria-selected='false'
                  >
                    Consultancy
                  </Link>
                </li>
              </ul>
              <div className='tab-content' id='nav-tabContent'>
                <div
                  className='tab-pane fade pt-60 active show'
                  id='tab-1'
                  role='tabpanel'
                >
                  <ProcessFlow
                    color="seo-color"
                    title1="Website SEO Audit"
                    description1="Identify the most critical technical SEO issues and create an action plan to improve website health and performance."
                    icon1="search"
                    img="assets/img/zogros/home/process-seo.png"
                    title2="Keyword Research"
                    description2="Conduct in-depth keyword research to target the right audience and optimize website content accordingly."
                    icon2="keyboard"
                    title3="On-Page Optimization"
                    description3="Implement on-page SEO strategies, including meta tags, headers, and content optimization."
                    icon3="file-code"
                    title4="Off-Page SEO"
                    description4="Build high-quality backlinks and optimize off-page elements to enhance website visibility."
                    icon4="link"
                    title5="Monitoring and Analytics"
                    description5="Track website performance and visitor behavior in real-time, ensuring compliance with privacy regulations like GDPR."
                    icon5="chart-line"
                    title6="Continuous Improvement"
                    description6="Regularly review and adjust SEO strategies to adapt to changing search engine algorithms and market trends."
                    icon6="sync-alt"
                  />

                </div>
                <div className='tab-pane fade pt-60' id='tab-2' role='tabpanel'>
                  <ProcessFlow
                    color="ai-color"
                    title1="Content Briefing"
                    description1="Provide AI with specific content requirements and industry context."
                    icon1="file-alt"
                    img="assets/img/zogros/home/process-ai.png"
                    title2="AI Content Generation"
                    description2="Utilize AI and machine learning to generate high-quality, unique, and engaging content."
                    icon2="robot"
                    title3="Content Review"
                    description3="Review AI-generated content for accuracy, coherence, and tone."
                    icon3="search"
                    title4="Customization"
                    description4="Tailor AI-generated content to match the brand voice and messaging."
                    icon4="edit"
                    title5="Content Optimization"
                    description5="Optimize AI content for SEO and user engagement."
                    icon5="cogs"
                    title6="Continuous Learning"
                    description6="Train AI models to improve content quality over time based on user feedback and performance."
                    icon6="graduation-cap"
                  />
                </div>
                <div className='tab-pane fade pt-60' id='tab-3' role='tabpanel'>
                  <ProcessFlow
                    color="analytics-color"
                    title1="Data Collection"
                    description1="Collect and aggregate user data from various sources while ensuring GDPR compliance."
                    icon1="database"
                    img="assets/img/zogros/home/process-analytics.png"
                    title2="Real-Time Tracking"
                    description2="Monitor website visitors in real-time, gaining insights without compromising privacy."
                    icon2="line-chart"
                    title3="Data Analysis"
                    description3="Analyze collected data to identify patterns, trends, and user behavior."
                    icon3="chart-pie"
                    title4="Custom Reporting"
                    description4="Generate customized reports and dashboards to visualize data effectively."
                    icon4="file-chart-line"
                    title5="Optimization Recommendations"
                    description5="Provide actionable insights and recommendations based on data analysis to improve website performance."
                    icon5="lightbulb"
                    title6="Security and Compliance"
                    description6="Ensure data security and compliance with privacy regulations in all analytics processes."
                    icon6="shield-alt"
                  />
                </div>
                <div className='tab-pane fade pt-60' id='tab-4' role='tabpanel'>
                  <ProcessFlow
                    color="creative-color"
                    title1="Creative Consultation"
                    description1="Collaborate with our team of designers and marketing experts to define your creative needs."
                    icon1="users"
                    img="assets/img/zogros/home/process-creative.png"
                    title2="Ideation and Conceptualization"
                    description2="Brainstorm creative ideas and concepts tailored to your business objectives."
                    icon2="lightbulb"
                    title3="Design and Development"
                    description3="Transform concepts into visually appealing and functional creative solutions."
                    icon3="paint-brush"
                    title4="Review and Feedback"
                    description4="Review creative work with clients, gather feedback, and make necessary revisions."
                    icon4="comment-dots"
                    title5="Quality Assurance"
                    description5="Thoroughly test and ensure the quality and effectiveness of creative deliverables."
                    icon5="check-circle"
                    title6="Delivery and Implementation"
                    description6="Finalize and implement creative solutions across relevant channels."
                    icon6="rocket"
                  />
                </div>
                <div className='tab-pane fade pt-60' id='tab-5' role='tabpanel'>
                  <ProcessFlow
                    color="url-color"
                    title1="URL Shortening"
                    description1="Shorten and simplify URLs for ease of use and tracking purposes."
                    icon1="link"
                    img="assets/img/zogros/home/process-url.png"
                    title2="Targeted Links"
                    description2="Create customized, targeted links to direct users to specific content or campaigns."
                    icon2="bullseye"
                    title3="Link Tracking"
                    description3="Track the performance of shortened links to measure their effectiveness."
                    icon3="chart-bar"
                    title4="Link Optimization"
                    description4="Optimize link structures for better user experience and engagement."
                    icon4="sitemap"
                    title5="Link Security"
                    description5="Implement security measures to protect against link abuse and fraud."
                    icon5="lock"
                    title6="Link Analytics"
                    description6="Analyze link click-through rates and user behavior to refine link strategies."
                    icon6="chart-line"
                  />
                </div>
                <div className='tab-pane fade pt-60' id='tab-6' role='tabpanel'>
                  <ProcessFlow
                    color="consultancy-color"
                    title1="Business Assessment"
                    description1="Conduct a comprehensive assessment of your business needs and goals."
                    icon1="clipboard-check"
                    img="assets/img/zogros/home/process-consultancy.png"
                    title2="Strategy Development"
                    description2="Develop personalized strategies to address your specific challenges and opportunities."
                    icon2="chart-line"
                    title3="Guidance and Support"
                    description3="Provide ongoing guidance, support, and training to help your business stay competitive."
                    icon3="hand-holding-heart"
                    title4="Implementation Assistance"
                    description4="Assist in the implementation of recommended strategies and solutions."
                    icon4="toolbox"
                    title5="Performance Monitoring"
                    description5="Monitor the performance of implemented strategies and make adjustments as needed."
                    icon5="tachometer-alt"
                    title6="Continuous Improvement"
                    description6="Continuously assess and refine strategies to keep your business ahead of the curve."
                    icon6="sync-alt"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZogrosProcessTab;
