import React, { useEffect } from "react";
import { use100vh } from 'react-div-100vh';
import { useActualScreenSize, useIsMobile } from "../../function";
import { Gradient } from "./Gradient";
import "./GradientCSS.css";


/*
*Finally initializing the Gradient class, assigning a canvas to it and calling Gradient.connect() which initializes everything,
* Use Gradient.pause() and Gradient.play() for controls.
*
* Here are some default property values you can change anytime:
* Amplitude:    Gradient.amp = 0
* Colors:       Gradient.sectionColors (if you change colors, use normalizeColor(#hexValue)) before you assign it.
*
*
* Useful functions
* Gradient.toggleColor(index)
* Gradient.updateFrequency(freq)
*/

const GradientPanel = () => {
    // const windowHeight = useHeight(); // Use the useHeight hook to get the window height
    const windowHeight = use100vh()
    const { height } = useActualScreenSize();
    const isMobile = useIsMobile();

    useEffect(() => {

        const gradient = new Gradient();
        gradient.initGradient('#gradient-canvas');
    }, []);

    return (
        <canvas style={isMobile ? { minHeight: `${height}px` } : { minHeight: `${windowHeight}px` }} id="gradient-canvas" data-js-darken-top data-transition-in />
    );
};

export default GradientPanel;
