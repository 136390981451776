import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, description, keywords, pageurl }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={pageurl} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content="Zogros" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://www.zogros.com" />
      <meta property="og:image" content="https://zogros.com/images/homepage/zogros-banner.png" />
      <meta property="og:description" content="Zogros provides digital services to companies across the UK Contact us today to learn more about what services can help your business grow." />
      <meta property="og:site_name" content="Zogros" />
    </Helmet>
  );
};

export default PageMeta;
