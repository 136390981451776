import React from "react";
import LatestBlog from "../../components/blog/LatestBlog";
import PageMeta from "../../components/common/PageMeta";
import CtaTwo from "../../components/cta/CtaTwo";
import FeatureTwo from "../../components/features/FeatureTwo";
import ReviewOne from "../../components/review/ReviewOne";
import TestimonialOne from "../../components/testimonial/TestimonialOne";
import WorkProcessTwo from "../../components/work-process/WorkProcessTwo";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import HeroTwo from "./HeroTwo";

const HomeSassTwo = () => {
  return (
    <Layout>
      <PageMeta title="Zogros - Software &amp; IT Solutions HTML Template" />
      <Navbar />
      <HeroTwo />
      <ReviewOne />
      <FeatureTwo />
      <WorkProcessTwo />
      <TestimonialOne hasSubtitle />
      {/* <IntegrationOne /> */}
      <LatestBlog />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeSassTwo;
