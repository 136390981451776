import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/common/SectionTitle';
import { openChatBot } from '../../function';
import WizardCard from './ZogrosReusable/WizardCard';

const ZogrosSmartWizard = () => {


  return (
    <>
      <section id='smartWizard' className='contact-us pb-120 pt-120 position-relative bg-light'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div className='col-12'>
              <SectionTitle
                subtitle='Quick Contact'
                title='Contact Us with Ease'
                description='Effortlessly navigate our personalized inquiry wizard to tailor your contact experience.'
              />

              <div className='row justify-content-between pb-5'>
                <div
                  className='col-12 col-lg-6 mb-4 mb-lg-0'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >
                  <WizardCard />
                </div>


                <div
                  className='col-12 col-lg-6 mb-4 mb-md-0 mb-lg-0'
                  data-aos='fade-up'
                  data-aos-delay='50'
                >

                  <div className='icon-box d-inline-block rounded-circle bg-primary-soft'>
                    <i className='fas fa-envelope fa-2x text-primary'></i>
                  </div>
                  <div className='contact-info'>
                    <h5>Email Us</h5>
                    <p>
                      Questions about our products or pricing? Email for support
                    </p>
                    <a
                      href='mail:support@zogros.com'
                      className='read-more-link text-decoration-none'
                    >
                      <i className='far fa-envelope me-2'></i> support@zogros.com
                    </a>
                  </div>
                  <br></br>
                  <div className='icon-box d-inline-block rounded-circle bg-danger-soft'>
                    <i className='fas fa-comment-alt-lines fa-2x text-danger'></i>
                  </div>
                  <div className='contact-info'>
                    <h5>Chat with Us</h5>
                    <p>
                      Our support will help you when they are available
                    </p>
                    <Link
                      onClick={() => openChatBot()}
                      className='read-more-link text-decoration-none'
                    >
                      <i className='far fa-comment-alt-dots me-2'></i> Live Chat
                      Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        {/* <div
          className='bg-dark position-absolute bottom-0 h-25 bottom-0 left-0 right-0 z--1 py-5'
          style={{
            background:
              " url('img/shape/dot-dot-wave-shape.svg')no-repeat center top",
          }}
        >
          <div className='bg-circle rounded-circle circle-shape-3 position-absolute bg-dark-light left-5'></div>
          <div className='bg-circle rounded-circle circle-shape-1 position-absolute bg-warning right-5'></div>
        </div> */}
      </section>
    </>
  );
};
export default ZogrosSmartWizard;
