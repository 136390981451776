import React from "react";
const Layout = ({ children, hasClass, classOpt }) => {
  return (
    <div
      className={`main-wrapper ${classOpt ? classOpt : ""} ${
        hasClass ? "overflow-hidden" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default Layout;
