import React, { createContext, useContext, useState } from 'react';

// Create a context for your provider
const ZogrosContext = createContext();

// Create the provider component
export const ZogrosProvider = ({ children }) => {
    const [data, setData] = useState();

    return (
        <ZogrosContext.Provider value={{ data, setData }}>
            {children}
        </ZogrosContext.Provider>
    );
};

// Create a custom hook to easily access the provider's functions
export const useZogrosContext = () => {
    const context = useContext(ZogrosContext);
    if (!context) {
        throw new Error('useMyContext must be used within a Zogros Provider');
    }
    return context;
};
