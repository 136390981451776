import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import CtaTwo from '../../components/cta/CtaTwo';
import Customer from '../../components/customer/Customer';
import FeatureImgEight from '../../components/features/FeatureImgEight';
import FeatureImgThree from '../../components/features/FeatureImgThree';
import FeatureTwo from '../../components/features/FeatureTwo';
import PromoThree from '../../components/promo/PromoThree';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';
import WorkProcessFour from '../../components/work-process/WorkProcessFour';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroSix from './HeroSix';

const HomeStartup = () => {
  return (
    <Layout>
      <PageMeta title='Startup - Software &amp; IT Solutions HTML Template' />
      <Navbar navDark posAbsolute />
      <HeroSix />
      <Customer gray />
      <PromoThree />
      <FeatureImgThree />
      <FeatureImgEight />
      <FeatureTwo cardDark />
      <WorkProcessFour />
      {/* <IntegrationTwo /> */}
      <TestimonialTwo bgWhite />
      <CtaTwo />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeStartup;
