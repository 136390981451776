import React from 'react';
import { Link } from 'react-router-dom';
import { DARK_ICON } from '../../const';
import { NavbarItems } from './NavbarItems';

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-1">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              src={DARK_ICON}
              alt="logo"
              className="img-fluid ps-2 mx-2"
              width="40"
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <NavbarItems />
          </ul>
          {/* <div className="action-btns mt-4 ps-3">
            <Link onClick={() => handleClickScroll("smartWizard")} className="btn btn-primary">
              Get Started
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
