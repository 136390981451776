import React, { useState } from 'react';

const ProcessFlow = ({ color, title1, description1, icon1, img, title2, description2, icon2, title3, description3, icon3, title4, description4, icon4, title5, description5, icon5, title6, description6, icon6 }) => {
    const [isHovered, setIsHovered] = useState([false, false, false, false, false, false]);

    const handleMouseEnter = (index) => {
        const newHoveredState = [...isHovered];
        newHoveredState[index] = true;
        setIsHovered(newHoveredState);
    };

    const handleMouseLeave = (index) => {
        const newHoveredState = [...isHovered];
        newHoveredState[index] = false;
        setIsHovered(newHoveredState);
    };

    // Define helper functions to get titles, descriptions, and icons based on the index
    function getTitle(index) {
        const titles = [title1, title2, title3, title4, title5, title6];
        return titles[index];
    }

    function getDescription(index) {
        const descriptions = [description1, description2, description3, description4, description5, description6];
        return descriptions[index];
    }

    function indexToIcon(index) {
        const icons = [icon1, icon2, icon3, icon4, icon5, icon6];
        return icons[index];
    }

    return (
        <div className='row align-items-center justify-content-between'>
            <div className='col-lg-5 col-md-12 order-1 order-lg-0'>
                <div className='img-wrap'>
                    <img
                        src={img}
                        alt='work process'
                        className='img-fluid'
                        style={{ borderRadius: "2rem" }}
                    />
                </div>
            </div>
            <div className='col-lg-6 col-md-12 order-0 order-lg-1'>
                <ul className='work-process-list list-unstyled'>
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <li
                            key={index}
                            className='d-flex align-items-start mb-4'
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                        >
                            <div className={`process-icon-2 border border-2 rounded-custom bg-white me-4 mt-2 ${isHovered[index] ? color : ''}`}>
                                <i className={`far fa-${indexToIcon(index)} fa-2x`}></i>
                            </div>
                            <div className='icon-content'>
                                <span className={`${color} h6`}>{`Step ${index + 1}`}</span>
                                <h3 className='h5 mb-2'>{getTitle(index)}</h3>
                                <p>{getDescription(index)}</p>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ProcessFlow;
