import { motion } from 'framer-motion';
import * as React from 'react';
import { useWizard } from 'react-use-wizard';

const variants = {
    enter: {
        opacity: 0,
    },
    center: {
        zIndex: 1,
        opacity: 1,
    },
    exit: {
        zIndex: 0,
        opacity: 0,
    },
};

const AnimatedCard = React.memo(({ children }) => {
    const { activeStep } = useWizard();
    const previousStepIndex = React.useRef(activeStep); // Store the current active step

    React.useEffect(() => {
        previousStepIndex.current = activeStep; // Update the stored active step
    }, [activeStep]);

    // Determine whether the card should be visible or not based on the current step
    const isVisible = activeStep === previousStepIndex.current;

    return (
        <motion.div
            style={{ display: isVisible ? 'block' : 'none' }} // Conditionally display the card
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
                type: 'linear',
                stiffness: 300,
                damping: 30,
            }}
        >
            {children}
        </motion.div>
    );
});

export default AnimatedCard;
