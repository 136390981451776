import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LOGO_DARK, LOGO_LIGHT } from '../../const';
import { useWindowSize } from '../../hooks/useWindowSize/useWindowSize';
import { NavbarItems } from './NavbarItems';
import OffCanvasMenu from './OffCanvasMenu';


const Navbar = ({ navDark }) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    const stickyheader = document.querySelector('.main-header');
    setHeaderTop(stickyheader.offsetTop);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (width >= 1200) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [width]);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };


  return (
    <>
      <header
        className={`main-header ${navDark ? 'position-absolute ' : ''
          } w-100 position-absolute `}
      >
        <nav
          className={`navbar navbar-expand-xl z-10 ${navDark ? 'navbar-dark' : 'navbar-light'
            } sticky-header ${scroll > headerTop ? 'affix' : ''}`}
        >
          <div className="container d-flex align-items-center justify-content-lg-between position-relative">
            <Link
              to="/"
              className="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none"
            >
              <img
                src={LOGO_LIGHT}
                alt="logo"
                className="img-fluid logo-white"
                width="150"
              />
              <img
                src={LOGO_DARK}
                alt="logo"
                className="img-fluid logo-color"
                width="150"
              />
            </Link>
            <Link
              className="navbar-toggler position-absolute right-0 border-0 p-4"
              to="#offcanvasWithBackdrop"
              role="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasWithBackdrop"
              aria-controls="offcanvasWithBackdrop"
            >
              <span
                className="far fa-bars"
              ></span>
            </Link>

            <div className="collapse navbar-collapse justify-content-center">
              <ul className="nav col-12 col-md-auto justify-content-center main-menu">
                <NavbarItems />
              </ul>
            </div>

            <div className="action-btns text-end me-5 me-lg-0 d-none d-md-block d-lg-block">
              {showButtons && (
                <>
                  <Link to="/get-started" className="btn btn-primary">
                    Get Started
                  </Link>
                </>
              )}
            </div>
            <OffCanvasMenu />
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
