import { Link } from 'react-router-dom';

export function NavbarItems() {
    return (<>
        <li>
            <Link to="/" className="nav-link">
                Home
            </Link>
        </li>
        <li>
            <Link to="/about-us" className="nav-link">
                About
            </Link>
        </li>
        <li>
            <Link to="/services" className="nav-link">
                Services
            </Link>
        </li>
        <li>
            <Link to="/pricing" className="nav-link">
                Pricing
            </Link>
        </li>
        <li>
            <Link to="/company" className="nav-link">
                Company
            </Link>
        </li>
    </>);
}