import React from "react";
import { Link } from "react-router-dom";
import { LOGO_LIGHT } from "../../const";
import { getCurrentYear } from "../../function";

const ZogrosFooter = () => {
  return (
    <footer className="cyber-footer bg-gradient">
      <div className="cyber-footer-top ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-lg-5 mb-md-4 mb-lg-0">
              <div className="">
                <div className="footer-single-col mb-4">
                  <img
                    src={LOGO_LIGHT}
                    alt="logo"
                    className="img-fluid logo-white"
                    style={{ height: 42 }}
                  />
                </div>
                <p className="text-white">
                  Our latest news, articles, and resources, we will sent to your
                  inbox weekly. Our latest news, articles, and resources, we
                  will sent to your inbox weekly.
                </p>
                <ul className="list-unstyled list-inline cyber-footer-social-list mb-0">
                  <li className="list-inline-item">
                    <a href="/#">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/#">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/#">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href="/#">
                      <i className="fab fa-github"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 col-lg-7 mt-4 mt-md-0 mt-lg-0">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="">
                    <h3 className="h5 mb-4 text-white">Company Services</h3>
                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                      <li>
                        <Link to="/" className="text-decoration-none">
                          SEO by Zogros
                        </Link>
                      </li>
                      <li>
                        <Link to="/about-us" className="text-decoration-none">
                          Analytics by Zogros
                        </Link>
                      </li>
                      <li>
                        <Link to="/services" className="text-decoration-none">
                          AI by Zogros
                        </Link>
                      </li>
                      <li>
                        <Link to="/career" className="text-decoration-none">
                          Dashboard by Zogros
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/integrations"
                          className="text-decoration-none"
                        >
                          Consultancy by Zogros
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://status.zogros.com"
                          className="text-decoration-none"
                        >
                          Status Page
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="">
                    <h3 className="h5 mb-4 text-white">Quick Links</h3>
                    <ul className="list-unstyled footer-nav-list mb-lg-0">
                      <li>
                        <Link to="/contact-us" className="text-decoration-none">
                          Contact Us
                        </Link>
                      </li>
                      <li>
                        <Link to="/about-us" className="text-decoration-none">
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link to="/services" className="text-decoration-none">
                          Privacy Policy
                        </Link>
                      </li>
                      <li>
                        <Link to="/career" className="text-decoration-none">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/integrations"
                          className="text-decoration-none"
                        >
                          Team
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cyber-footer-bottom">
        <div className="container">
          <div className="row ">
            <div className="col-12 align-items-center justify-content-center">
              <p className="mb-0 py-4 text-center">
                Copyright {getCurrentYear()} All Rights Reserved by <img src={LOGO_LIGHT} width="100px" alt="Zogros Logo" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ZogrosFooter;
