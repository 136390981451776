import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/common/SectionTitle';
import { useIsMobile } from "../../function";


const brands = [
  { name: 'Air Table', image: "/assets/img/zogros/companies/airtable.svg" },
  { name: 'Atlassian', image: "/assets/img/zogros/companies/atlassian.svg" },
  { name: 'AWS', image: "/assets/img/zogros/companies/aws.svg" },
  { name: 'Buffer', image: "/assets/img/zogros/companies/buffer.svg" },
  { name: 'Cloudflare', image: "/assets/img/zogros/companies/cloudflare-icon.svg" },
  { name: 'Eventbrite', image: "/assets/img/zogros/companies/eventbrite-icon.svg" },
  { name: 'Facebook', image: "/assets/img/zogros/companies/facebook.svg" },
  { name: 'Figma', image: "/assets/img/zogros/companies/figma.svg" },
  { name: 'Google Analytics', image: "/assets/img/zogros/companies/google-analytics.svg" },
  { name: 'Google Cloud', image: "/assets/img/zogros/companies/google-cloud.svg" },
  { name: 'Google Domains', image: "/assets/img/zogros/companies/google-domains-icon.svg" },
  { name: 'Hootsuite', image: "/assets/img/zogros/companies/hootsuite-icon.svg" },
  { name: 'HubSpot', image: "/assets/img/zogros/companies/hubspot.svg" },
  { name: 'Mailchimp', image: "/assets/img/zogros/companies/mailchimp-freddie.svg" },
  { name: 'Mailgun', image: "/assets/img/zogros/companies/mailgun-icon.svg" },
  { name: 'Segment', image: "/assets/img/zogros/companies/segment-icon.svg" },
  { name: 'SendGrid', image: "/assets/img/zogros/companies/sendgrid-icon.svg" },
  { name: 'Shopify', image: "/assets/img/zogros/companies/shopify.svg" },
  // { name: 'Slack', image: "/assets/img/zogros/companies/slack-icon.svg" },
  // { name: 'Spotify', image: "/assets/img/zogros/companies/spotify-icon.svg" },
  // { name: 'Storyblok', image: "/assets/img/zogros/companies/storyblok-icon.svg" },
  // { name: 'Stripe', image: "/assets/img/zogros/companies/stripe.svg" },
  // { name: 'Typeform', image: "/assets/img/zogros/companies/typeform-icon.svg" },
  // { name: 'Zoho', image: "/assets/img/zogros/companies/zoho.svg" },
  // Add more brands here with their names and image paths
];


const ZogrosIntegrationList = () => {
  const isMobile = useIsMobile();

  console.log(isMobile)
  return (
    <>
      <section className='integration-section bg-light ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-lg-between'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                subtitle='Integration'
                title='We Collaborate with Top Software Companies'
                description='We specialize in seamlessly integrating top software solutions into your business processes. Our expertise allows us to harness the power of industry-leading software tools, enabling your organization to operate more efficiently and effectively. With our tailored integration services, you can make the most of these software solutions without the need for official partnerships.'

                leftAlign
              />
            </div>
            <div className='col-lg-4 col-md-12'>
              <div className='text-lg-end mb-5 mb-lg-0' data-aos='fade-left'>
                <Link to='/integrations' className='btn btn-primary'>
                  View All Integrations
                </Link>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <div className='integration-wrapper position-relative w-100'>
                <ul className='integration-list list-unstyled mb-0'>
                  {isMobile ? brands.slice(0, 6).map((brand, index) => (
                    <li key={index} data-aos='fade-up' data-aos-delay='50'>
                      <div className='single-integration' style={{ borderRadius: "1rem" }}>
                        <img
                          src={brand.image}
                          alt='integration'
                          className='img-fluid'
                          style={{ height: 100, margin: 10 }}
                        />
                        <h6 className='mb-0 mt-3'>{brand.name}</h6>
                      </div>
                    </li>
                  )) :
                    brands.map((brand, index) => (
                      <li key={index} data-aos='fade-up' data-aos-delay='50'>
                        <div className='single-integration' style={{ borderRadius: "1rem" }}>
                          <img
                            src={brand.image}
                            alt='integration'
                            className='img-fluid'
                            style={{ height: 100, margin: 10 }}
                          />
                          <h6 className='mb-0 mt-3'>{brand.name}</h6>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZogrosIntegrationList;
