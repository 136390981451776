import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SOFTWARE_CARDS } from "../../const";
import CTACards from './ZogrosReusable/CTACards';

const ZogrosSoftwareCard = ({ url, css, title, img }) => {
  return (<a href={url} className={css + " p-2 p-xs-1"} data-bs-toggle='tooltip' data-bs-placement='top' title={title}>
    <img src={img} alt='integration' className='img-fluid' />
  </a>);
}

// const TAG = [
//   "3D Designer",
//   "Brand designer",
//   "Copywriter",
//   "Product designer",
//   "Motion designer",
//   "Developer",
//   "UI designer",
//   "UX designer",
//   "Brand strategist",
//   "Project manager",
//   "Illustrator",
//   "Animator",
//   "3D Designer",
//   "Brand designer",
//   "Copywriter",
//   "Product designer",
//   "Motion designer",
//   "Developer",
//   "UI designer",
//   "UX designer",
//   "Brand strategist",
//   "Project manager",
//   "Illustrator",
//   "Animator",
//   "3D Designer",
//   "Brand designer",
//   "Copywriter",
//   "Product designer",
//   "Motion designer",
//   "Developer",
//   "UI designer",
//   "UX designer",
//   "Brand strategist",
//   "Project manager",
//   "Illustrator",
//   "Animator"
// ]

// const TAG2 = [
//   "Web Developer",
//   "Graphic Designer",
//   "Content Writer",
//   "UX/UI Designer",
//   "Video Editor",
//   "Software Engineer",
//   "Frontend Developer",
//   "Backend Developer",
//   "Marketing Specialist",
//   "Product Manager",
//   "Photographer",
//   "Game Developer",
//   "Data Analyst",
//   "Art Director",
//   "SEO Specialist",
//   "Database Administrator",
//   "Network Engineer",
//   "UI Designer",
//   "Brand Manager",
//   "Digital Marketer",
//   "Financial Analyst",
//   "Social Media Manager",
//   "Architect",
//   "Business Analyst",
//   "UI/UX Designer",
//   "Project Coordinator",
//   "Animator",
//   "Illustrator",
//   "Copy Editor",
//   "Sales Manager",
//   "E-commerce Specialist",
//   "Customer Support Representative",
//   "HR Manager",
//   "Video Game Tester",
//   "Cybersecurity Analyst",
// ];



const ZogrosSoftwareList = () => {
  const [randomCards, setRandomCards] = useState([]);

  useEffect(() => {
    const shuffledCards = SOFTWARE_CARDS.sort(() => Math.random() - 0.5);
    // Take the first two cards
    const selectedCards = shuffledCards.slice(0, 2);
    setRandomCards(selectedCards);
  }, []);


  return (
    <>
      <section className='integration-section ptb-120'>
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <div className='col-lg-3'>
              <div className='integration-list-wrap'>
                <ZogrosSoftwareCard url="https://dashboard.zogros.com" css="integration-1 bg-dashboard-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                <ZogrosSoftwareCard url="https://seo.zogros.com" css="integration-2 bg-seo-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                <ZogrosSoftwareCard url="https://consultancy.zogros.com" css="integration-3 bg-consultancy-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                <ZogrosSoftwareCard url="https://url.zogros.com" css="integration-4 bg-url-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                <ZogrosSoftwareCard url="https://analytics.zogros.com" css="integration-5 bg-analytics-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                <ZogrosSoftwareCard url="https://ai.zogros.com" css="integration-6 bg-ai-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <div className='section-heading text-center my-5 my-lg-0 my-xl-0'>
                <h4 className='text-primary h5'>Our Software</h4>
                <h2>We Have Created the Best Suite of Software to Kickstart Your Digital Journey.</h2>
                <Link to='/integrations' className='mt-4 btn btn-primary'>
                  View all of our Software
                </Link>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className='col-lg-4'>
                <div className='integration-list-wrap'>
                  <ZogrosSoftwareCard url="https://ai.zogros.com" css="integration-7 bg-ai-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                  <ZogrosSoftwareCard url="https://analytics.zogros.com" css="integration-8 bg-analytics-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                  <ZogrosSoftwareCard url="https://url.zogros.com" css="integration-9 bg-url-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                  <ZogrosSoftwareCard url="https://consultancy.zogros.com" css="integration-10 bg-consultancy-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                  <ZogrosSoftwareCard url="https://seo.zogros.com" css="integration-11 bg-seo-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                  <ZogrosSoftwareCard url="https://dashboard.zogros.com" css="integration-12 bg-dashboard-color" title="Zogros Dashbaord" img="assets/img/zogros/z-icon.svg" />
                </div>
              </div>
            </div>
          </div>
          <div className='row justify-content-center mt-100'>
            {randomCards.map((card, index) => (
              <CTACards
                key={index}
                title={card.title}
                description={card.description}
                buttonText={card.buttonText}
                buttonLink={card.buttonLink}
                imageSrc={card.imageSrc}
                altText={card.altText}
                cardColor={card.cardColor}
              />
            ))}
          </div>
        </div>
        {/* <PillRotation list1={TAG} list2={TAG2} /> */}
      </section>
    </>
  );
};

export default ZogrosSoftwareList;




