export const LOGO_LIGHT = 'assets/img/brand-logo/light-text.svg';
export const LOGO_DARK = 'assets/img/brand-logo/dark-text.svg';
export const WHITE_ICON = 'assets/img/brand-logo/white-icon.svg';
export const DARK_ICON = 'assets/img/brand-logo/dark-icon.svg';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/zogros';
export const Z_ICON = 'assets/img/zogros/z-icon.svg';

export const SLACK_WEBHOOK_URL = process.env.REACT_APP_SLACK_WEBHOOK_URL;


export const SOFTWARE_CARDS = [
    {
        title: "AI-Driven Digital Mastery",
        description: "Harnessing the power of artificial intelligence to propel your business into the future.",
        buttonText: "Unlock AI Power",
        buttonLink: "https://ai.zogros.com",
        imageSrc: "assets/img/zogros/home/ai.png",
        altText: "cta",
        cardColor: "danger"
    },
    {
        title: "Privacy-Centric Analytics Insight",
        description: "Dive deep into your data without compromise, ensuring GDPR compliance and user trust.",
        buttonText: "Track Safely",
        buttonLink: "https://analytics.zogros.com",
        imageSrc: "assets/img/zogros/home/analytics.png",
        altText: "analytics",
        cardColor: "info"
    },
    {
        title: "Technical SEO Excellence",
        description: "Diagnose and optimize your website's health for peak performance and organic growth.",
        buttonText: "Optimize Now",
        buttonLink: "https://seo.zogros.com",
        imageSrc: "assets/img/zogros/home/seo.png",
        altText: "seo",
        cardColor: "success"
    },
    {
        title: "URL Management Perfected",
        description: "Advanced tools to shorten, target, and track your links effectively.",
        buttonText: "Shorten & Target",
        buttonLink: "https://url.zogros.com",
        imageSrc: "assets/img/zogros/home/url.png",
        altText: "url",
        cardColor: "primary"
    },
    {
        title: "Bespoke Creative Solutions",
        description: "Designs and strategies tailored by industry-leading experts for your brand.",
        buttonText: "Craft Your Brand",
        buttonLink: "https://creative.zogros.com",
        imageSrc: "assets/img/zogros/home/creative.png",
        altText: "creative",
        cardColor: "warning"
    },
    {
        title: "Business Consultancy Expertise",
        description: "Guidance, support, and training to ensure you're always at the forefront.",
        buttonText: "Stay Ahead",
        buttonLink: "https://consultancy.zogros.com",
        imageSrc: "assets/img/zogros/home/consultancy.png",
        altText: "consultancy",
        cardColor: ""
    }
];