import React from 'react';

const CTACards = ({ title, description, buttonText, buttonLink, imageSrc, altText, cardColor }) => {
    return (
        <div className='col-lg-6 mt-4'>
            <div className={`position-relative d-flex flex-column h-100 flex-wrap bg-${cardColor ? cardColor : "dark"}-soft p-5 rounded-custom`}>
                <div className='cta-left-info mb-2'>
                    <h5>{title}</h5>
                    <p>{description}</p>
                </div>
                <div className='action-btns mt-auto'>
                    <a
                        href={buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className='btn btn-light btn-sm'
                    >
                        {buttonText}
                    </a>
                </div>
                <div className='cta-img position-absolute right-0 bottom-0'>
                    <img
                        src={imageSrc}
                        alt={altText}
                        className='img-fluid'
                    />
                </div>
            </div>
        </div>
    );
};

export default CTACards;
