import React, { useEffect, useState } from "react";
import Tilt from 'react-parallax-tilt';
import SectionTitle from '../../components/common/SectionTitle';
import { SOFTWARE_CARDS } from "../../const";
import CTACards from './ZogrosReusable/CTACards';


const ZogrosFeatureOne = () => {
  const [randomCards, setRandomCards] = useState([]);

  useEffect(() => {
    const shuffledCards = SOFTWARE_CARDS.sort(() => Math.random() - 0.5);
    // Take the first two cards
    const selectedCards = shuffledCards.slice(0, 2);
    setRandomCards(selectedCards);
  }, []);

  return (
    <>
      <section id="featureOne" className='feature-promo ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                subtitle="Solution"
                title='Empowering Digital Evolution'
                description='Comprehensive solutions tailored to navigate your business through the digital frontier.'
                centerAlign
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-success-soft'>
                  <i className='fa fa-magnifying-glass fa-2x text-success'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'> Technical SEO & Performance</h3>
                  <p>
                    We diagnose and resolve critical SEO challenges, ensuring your website operates at peak performance, drawing in the right traffic.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>

                <div className='feature-icon icon-center pb-5 rounded-custom bg-danger-soft'>
                  <Tilt>
                    <i className='fa fa-brain fa-2x text-danger'></i>
                  </Tilt>
                </div>

                <div className='feature-info-wrap'>
                  <h3 className='h5'>AI-Powered Content</h3>
                  <p>
                    Leveraging advanced AI and machine learning, we craft unique and engaging content that resonates with your target audience, across various industries.{' '}
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='single-feature-promo p-lg-5 p-3 text-center mt-4'>
                <div className='feature-icon icon-center pb-5 rounded-custom bg-dark-soft'>
                  <i className='fa fa-handshake fa-2x text-dark'></i>
                </div>
                <div className='feature-info-wrap'>
                  <h3 className='h5'>Expert Consultancy</h3>
                  <p>
                    Our seasoned experts offer personalized guidance and training, enabling businesses to stay a step ahead in the evolving digital landscape.{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='row pt-60'>
            {randomCards.map((card, index) => (
              <CTACards
                key={index}
                title={card.title}
                description={card.description}
                buttonText={card.buttonText}
                buttonLink={card.buttonLink}
                imageSrc={card.imageSrc}
                altText={card.altText}
                cardColor={card.cardColor}
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ZogrosFeatureOne;
