import React, { useState } from "react";
import { use100vh } from 'react-div-100vh';
import ModalVideo from "react-modal-video";
import { Link } from "react-router-dom";
import HeroTitle from "../../components/common/HeroTitle";
import { handleClickScroll, useActualScreenSize, useIsMobile } from "../../function";
import GradientPanel from './GradientPanel';
// import { Player } from '@lottiefiles/react-lottie-player';
// import Hero from "../../lottie/hero.json";
// import { useWindowSize } from "../../hooks/useWindowSize/useWindowSize";

const ZogrosHero = () => {
  const [isOpen, setOpen] = useState(false);
  const windowHeight = use100vh()
  const { height } = useActualScreenSize();
  const isMobile = useIsMobile();

  // const { width } = useWindowSize();
  // const [hidden, setHidden] = useState(false);

  return (
    <>
      <GradientPanel />

      <section style={isMobile ? { minHeight: `${height}px` } : { minHeight: `${windowHeight}px` }} className="hero-section ptb-120 text-white bg-gradient d-flex align-items-start align-items-sm-center">
        <div className="container justify-content-center d-flex" style={{ zIndex: 999, padding: 0 }} >
          <div className="row align-items-center w-100">
            <div className="col-lg-6 col-md-6">
              <div className="hero-content-wrap mt-5 mt-lg-0 mt-xl-0" >
                <HeroTitle
                  title="Your All in One Digital Solution Partner"
                  desc="Unleashing Creativity and Technical Expertise for Unparalleled Digital Solutions, Ensuring Customer Satisfaction Every Step of the Way."
                />
                <div
                  className="action-btn align-items-center d-flex d-sm-flex d-lg-flex d-md-flex gap-3 gap-md-1 gap-lg-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="hAP2QF--2Dg"
                    onClose={() => setOpen(false)}
                  />
                  <button onClick={() => handleClickScroll("featureOne")} className="btn btn-light me-3">
                    Find Out More
                  </button>
                  <Link
                    to="#"
                    className="text-white text-decoration-none d-inline-flex align-items-center watch-now-btn mt-lg-0 mt-md-0"
                    onClick={() => setOpen(true)}
                  >
                    <i className="fas fa-play"></i> Watch Demo
                  </Link>
                </div>
                {/* <div className="row justify-content-lg-start mt-40">
                  <div className="col-12 h-25 ps-lg-0">
                    <img
                      src="assets/img/zogros/home/meshgr-home-hero.svg"
                      alt="client"
                      className="img-fluid"
                    />

                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 mt-4">
              <div className="hero-img position-relative circle-shape-images">

                <img
                  style={{ transform: "scale(1.03)" }}
                  src="assets/img/zogros/hero/hero.svg"
                  alt="hero img"
                  className="img-fluid position-relative z-5"
                />


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ZogrosHero;
